<template>
  <div class="box">
    <!-- 头部 -->
    <div class="top_box">
      <div class="box1">
        <div class="in_box">
          <div class="all_men">总人数:{{ list.length }}人</div>
          <div class="all_points">总积分：{{ all_points }}分</div>
          <img src="./img/money.png" />
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="number">{{ index + 1 }}</div>
        <img v-if="item.avatar==null" src="@/assets/img/default_avatar.png" class="headImg"  />
        <img v-else :src="item.avatar" class="headImg" />
        <div class="name">{{ item.nickName }}</div>
        <div class="points">{{ item.totalPointCount|| 0 }}分</div>
        <img src="./img/money.png" class="moneyIcon" />
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserListUrl } from "./api.js";
export default {
  name: "communityMembers",
  data() {
    return {
      id: "",
      list: [],
      finishedText: "没有更多了",
      finished: false,
      all_points: 0,
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    let idStr = this.$route.query.id;
    this.id = idStr;
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        id: this.id,
      };
      this.$axios
        .get(`${getUserListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data.userList);
            this.list = res.data.userList;
            this.all_points = res.data.totalPointCount;
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #f5f5f5;

  .list {
    width: 100%;
    height: auto;

    .item {
      width: 100%;
      height: 132px;
      background: #fff;
      position: relative;

      .number {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        color: #666666;
        font-size: 32px;
        font-weight: 600;
      }

      .headImg {
        position: absolute;
        top: 50%;
        left: 80px;
        transform: translateY(-50%);
        width: 84px;
        height: 84px;
        border-radius: 50%;
      }

      .name {
        position: absolute;
        top: 50%;
        left: 192px;
        transform: translateY(-50%);
        font-size: 32px;
        color: #333333;
      }

      .points {
        position: absolute;
        top: 50%;
        right: 82px;
        transform: translateY(-50%);
        font-size: 32px;
        color: #333333;
      }

      .moneyIcon {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
      }

      .line {
        width: 718px;
        height: 1px;
        background: rgba(0, 0, 0, 0.06);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .top_box {
    width: 100%;
    height: 108px;

    .box1 {
      width: 100%;
      height: 108px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      background: #f5f5f5;

      .in_box {
        width: 100%;
        height: 100%;
        position: relative;

        .all_men {
          position: absolute;
          top: 50%;
          left: 32px;
          transform: translateY(-50%);
          white-space: nowrap;
          font-size: 32px;
          color: #666666;
          font-weight: 600;
        }

        .all_points {
          position: absolute;
          top: 50%;
          right: 82px;
          transform: translateY(-50%);
          white-space: nowrap;
          font-size: 32px;
          color: #666666;
          font-weight: 600;
        }

        img {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
